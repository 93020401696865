import { IApplicationState } from "..";
import { configs } from "../../configs";
import { fetchCreator } from "../../../src/utils/middleware/fetch";
import {
  IExtraPreferences,
  IUserModulePreferences,
  IUserPreferenceState,
} from "./UserPreferenceState";
import { IDropdownOption } from "@fluentui/react";

export enum actionTypes {
  GET_USER_PREFERENCE = "GET_USER_PREFERENCE",
  USER_PREFERENCE_RECEIVED = "USER_PREFERENCE_RECEIVED",
  USER_PREFERENCE_UPDATED = "USER_PREFERENCE_UPDATED",
  USER_PREFERENCE_FETCH_FAILED = "USER_PREFERENCE_FETCH_FAILED",
  UPDATE_APP_PREFERENCES = "UPDATE_APP_PREFERENCES",
  RESET_APP_PREFERENCES = "RESET_APP_PREFERENCES",
  UPDATE_USER_MANAGEMENT_PREFERENCES = "UPDATE_USER_MANAGEMENT_PREFERENCES",
  UPDATE_REVIEWER_REVIEW_PACKAGE_PREFERENCES = "UPDATE_REVIEWER_REVIEW_PACKAGE_PREFERENCES",
  UPDATE_REVIEWER_SUBMITTED_PACKAGES_PREFERENCES = "UPDATE_REVIEWER_SUBMITTED_PACKAGES_PREFERENCES",
  UPDATE_PACKAGE_MANAGEMENT_PREFERENCES = "UPDATE_PACKAGE_MANAGEMENT_PREFERENCES",
  UPDATE_PACKAGE_MANAGEMENT_SUBMITTED_ONLY_PREFERENCES = "UPDATE_PACKAGE_MANAGEMENT_SUBMITTED_ONLY_PREFERENCES",
  UPDATE_QUEUE_MANAGEMENT_PREFERENCES = "UPDATE_QUEUE_MANAGEMENT_PREFERENCES",
  UPDATE_REVIEWER_REVIEW_PACKAGE_PREFERENCES_EXTRA = "UPDATE_REVIEWER_REVIEW_PACKAGE_PREFERENCES_EXTRA",
  UPDATE_TNSEXPLORE_CUSTOMERS = "UPDATE_TNSEXPLORE_CUSTOMERS",
  UPDATE_TNSEXPLORE_NETWORK_EXAMINE = "UPDATE_TNSEXPLORE_NETWORK_EXAMINE",
  UPDATE_TNSEXPLORE_INSIGHTS_EXAMINE = "UPDATE_TNSEXPLORE_INSIGHTS_EXAMINE",
  UPDATE_TNSEXPLORE_RISK_EXAMINE = "UPDATE_TNSEXPLORE_RISK_EXAMINE",
  UPDATE_TNSEXPLORE_MCA_EXAMINE = "UPDATE_TNSEXPLORE_MCA_EXAMINE",
  UPDATE_TNSEXPLORE_INSIGHTS_CARDS = "UPDATE_TNSEXPLORE_INSIGHTS_CARDS",
  UPDATE_TNSEXPLORE_ACCOUNTS = "UPDATE_TNEXPLORE_ACCOUNTS",
  UPDATE_TNSEXPLORE_STORES = "UPDATE_TNEXPLORE_STORES",
  UPDATE_TNSEXPLORE_USERS = "UPDATE_TNSEXPLORE_USERS",
  UPDATE_TNSEXPLORE_DOMAINS = "UPDATE_TNSEXPLORE_DOMAINS",
  UPDATE_TNSEXPLORE_INVESTIGATE = "UPDATE_TNSEXPLORE_INVESTIGATE",
  UPDATE_CUSTOMER_PROFILE_ACCOUNTS = "UPDATE_CUSTOMER_PROFILE_ACCOUNTS",
  UPDATE_CUSTOMER_PROFILE_DOMAINS = "UPDATE_CUSTOMER_PROFILE_DOMAINS",
  UPDATE_CUSTOMER_PROFILE_USERS = "UPDATE_CUSTOMER_PROFILE_USERS",
  UPDATE_CUSTOMER_PROFILE_PAYMENTS = "UPDATE_CUSTOMER_PROFILE_PAYMENTS",
  UPDATE_CUSTOMER_PROFILE_STORES = "UPDATE_CUSTOMER_PROFILE_STORES",
  UPDATE_TNS_EXPLORER_PREFERENCES_EXTRA = "UPDATE_TNS_EXPLORER_PREFERENCES_EXTRA",
  UPDATE_ESCALATIONS_JOB_STATUS_PREFERENCES = "UPDATE_ESCALATIONS_JOB_STATUS_PREFERENCES",
  UPDATE_ESCALATIONS_JOB_REVIEW_PREFERENCES = "UPDATE_ESCALATIONS_JOB_REVIEW_PREFERENCES",
  UPDATE_LISTMANAGER_SEEDTOKEN_JOB_STATUS_PREFERENCES = "UPDATE_LISTMANAGER_SEEDTOKEN_JOB_STATUS_PREFERENCES",
  UPDATE_MISMATCH_REVIEW_PACAKGE_PREFERENCES = "UPDATE_MISMATCH_REVIEW_PACAKGE_PREFERENCES",
  UPDATE_MISMATCH_REVIEW_PACKAGE_PREFERENCES_EXTRA = "UPDATE_MISMATCH_REVIEW_PACKAGE_PREFERENCES_EXTRA",
  UPDATE_MISMATCH_PACAKGES_PREFERENCES = "UPDATE_MISMATCH_PACAKGES_PREFERENCES",
  UPDATE_REJECTION_REVIEW_PACAKGE_PREFERENCES = "UPDATE_REJECTION_REVIEW_PACAKGE_PREFERENCES",
  UPDATE_REJECTION_REVIEW_PACKAGE_PREFERENCES_EXTRA = "UPDATE_REJECTION_REVIEW_PACKAGE_PREFERENCES_EXTRA",
  UPDATE_REJECTION_PACAKGES_PREFERENCES = "UPDATE_REJECTION_PACAKGES_PREFERENCES",
  UPDATE_QREREVIEWS_REVIEW_PACAKGE_PREFERENCES = "UPDATE_QREREVIEWS_REVIEW_PACAKGE_PREFERENCES",
  UPDATE_QREREVIEWS_REVIEW_PACKAGE_PREFERENCES_EXTRA = "UPDATE_QREREVIEWS_REVIEW_PACKAGE_PREFERENCES_EXTRA",
  UPDATE_QREREVIEWS_PACAKGES_PREFERENCES = "UPDATE_QREREVIEWS_PACAKGES_PREFERENCES",
  UPDATE_SEARCH_TRADEMARK_PREFERENCES = "UPDATE_SEARCH_TRADEMARK_PREFERENCES",
  UPDATE_IMPORT_TRADEMARK_PREFERENCES = "UPDATE_IMPORT_TRADEMARK_PREFERENCES",
  UPDATE_SEARCH_QRE_PREFERENCES = "UPDATE_SEARCH_QRE_PREFERENCES",
  UPDATE_SEARCH_PUBLISHER_RULE_PREFERENCES = "UPDATE_SEARCH_PUBLISHER_RULE_PREFERENCES",
  UPDATE_SEARCH_DSA_BLOCK_PREFERENCES = "UPDATE_SEARCH_DSA_BLOCK_PREFERENCES",
  UPDATE_SEARCH_PLA_BLOCK_PREFERENCES = "UPDATE_SEARCH_PLA_BLOCK_PREFERENCES",
  UPDATE_CUSTOMER_DECISION_HISTORY = "UPDATE_CUSTOMER_DECISION_HISTORY",
  UPDATE_CUSTOMER_DETECTION_HISTORY = "UPDATE_CUSTOMER_DETECTION_HISTORY",
  UPDATE_CUSTOMER_AIV_DATA = "UPDATE_CUSTOMER_AIV_DATA",
  UPDATE_TNSENFORCEMENT_HISTORY_PREFERENCES = "UPDATE_TNSENFORCEMENT_HISTORY_PREFERENCES",
  UPDATE_TNSDETECTION_HISTORY_PREFERENCES = "UPDATE_TNSENFORCEMENT_HISTORY_PREFERENCES",
  UPDATE_SEARCH_ENTITY_LIST_PREFERENCES = "UPDATE_SEARCH_ENTITY_LIST_PREFERENCES",
  UPDATE_SEARCH_DEFAULT_RULE_PREFERENCES = "UPDATE_SEARCH_DEFAULT_RULE_PREFERENCES",
  UPDATE_SEARCH_TERM_OVERRIDE_PREFERENCES = "UPDATE_SEARCH_TERM_OVERRIDE_PREFERENCES",
  UPDATE_SEARCH_FLAG_OVERRIDE_PREFERENCES = "UPDATE_SEARCH_FLAG_OVERRIDE_PREFERENCES",
  UPDATE_SEARCH_URL_RULE_PREFERENCES = "UPDATE_SEARCH_URL_RULE_PREFERENCES",
  UPDATE_ET_FLAG_PREFERENCES = "UPDATE_ET_FLAG_PREFERENCES",
  UPDATE_ET_CATEGORY_PREFERENCES = "UPDATE_ET_CATEGORY_PREFERENCES",
  UPDATE_ET_STATUS_PREFERENCES = "UPDATE_ET_STATUS_PREFERENCES",
  UPDATE_ET_HISTORY_PREFERENCES = "UPDATE_ET_HISTORY_PREFERENCES",
  UPDATE_ET_FA_OVERRIDE_PREFERENCES = "UPDATE_ET_FA_OVERRIDE_PREFERENCES",
  UPDATE_ET_RULE_OVERRIDE_PREFERENCES = "UPDATE_ET_RULE_OVERRIDE_PREFERENCES",
  UPDATE_ET_CUST_PILOT_FEATURE_PREFERENCES = "UPDATE_ET_CUST_PILOT_FEATURE_PREFERENCES",
  UPDATE_ET_EDITORIAL_CUST_PILOT_FEATURE_PREFERENCES = "UPDATE_ET_EDITORIAL_CUST_PILOT_FEATURE_PREFERENCES",
}

export const actionCreators = {
  getUserPreference: () => async (dispatch: any) => {
    try {
      dispatch({
        type: actionTypes.GET_USER_PREFERENCE,
        payload: {},
      });

      const response = await dispatch(
        fetchCreator(
          configs.client.endpoint.appServiceEndpoint + "me/preferences"
        )
      );
      const userPreference = await response.json();

      dispatch({
        type: actionTypes.USER_PREFERENCE_RECEIVED,
        payload: userPreference as IUserPreferenceState,
      });

      return userPreference;
    } catch (error: any) {
      dispatch({
        type: actionTypes.USER_PREFERENCE_FETCH_FAILED,
        error: error.message,
      });

      throw error;
    }
  },

  updateAppPreferences: (appPreferences) => async (dispatch: any) => {
    dispatch({
      type: actionTypes.UPDATE_APP_PREFERENCES,
      payload: appPreferences,
    });

    dispatch(actionCreators.updateUserPreference());
  },

  resetAppPreferences: () => async (dispatch: any) => {
    dispatch({
      type: actionTypes.RESET_APP_PREFERENCES,
      payload: {},
    });

    dispatch(actionCreators.updateUserPreference());
  },

  updateUserManagementPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_USER_MANAGEMENT_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateSearchTrademarkPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_SEARCH_TRADEMARK_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },
  updateImportTrademarkPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_IMPORT_TRADEMARK_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateSearchDsaBlockPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_SEARCH_DSA_BLOCK_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateSearchEntityListPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_SEARCH_ENTITY_LIST_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },
  updateSearchPlaBlockPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_SEARCH_PLA_BLOCK_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateSearchQrePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_SEARCH_QRE_PREFERENCES,
        payload: preferences,
      });
      dispatch(actionCreators.updateUserPreference());
    },

  updateSearchPublisherRulePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_SEARCH_PUBLISHER_RULE_PREFERENCES,
        payload: preferences,
      });
      dispatch(actionCreators.updateUserPreference());
    },
  updateReviewerReviewPackagePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_REVIEWER_REVIEW_PACKAGE_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateReviewerReviewPackagePreferencesExtra:
    (extraPreferences: IExtraPreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_REVIEWER_REVIEW_PACKAGE_PREFERENCES_EXTRA,
        payload: extraPreferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateReviewerSubmittedPackagesPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_REVIEWER_SUBMITTED_PACKAGES_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateUserPreference:
    () => async (dispatch: any, getState: () => IApplicationState) => {
      try {
        const userPreference = getState().userPreference;

        const fetchAction = fetchCreator(
          configs.client.endpoint.appServiceEndpoint + "me/preferences",
          {
            method: "PUT",
            body: JSON.stringify(userPreference),
          }
        );
        const debounceAction = {
          ...fetchAction,
          meta: {
            debounce: {
              name: "updateUserPreference",
              time: 1000,
            },
          },
        };

        dispatch(debounceAction);
      } catch (error: any) {}
    },

  updatePackageManagementPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_PACKAGE_MANAGEMENT_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updatePackageManagementSubmittedOnlyPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_PACKAGE_MANAGEMENT_SUBMITTED_ONLY_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateQueueManagementPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_QUEUE_MANAGEMENT_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateTNSExploreCustomersPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_CUSTOMERS,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updatePresetsTNSInsightsExaminePanelPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_INSIGHTS_EXAMINE,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updatePresetsTNSInsightsCardsPanelPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_INSIGHTS_CARDS,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updatePresetsTNSNetworkViewExaminePanelPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_NETWORK_EXAMINE,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateTNSExploreAccountsPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_ACCOUNTS,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateTNSExploreStoresPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_STORES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateTNSExploreUsersPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_USERS,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateTNSExploreDomainsPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_DOMAINS,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateTNSExploreInvestigatePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_INVESTIGATE,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateTNSExploreRiskExaminePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_RISK_EXAMINE,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },
  updateTNSExploreMCAExaminePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSEXPLORE_MCA_EXAMINE,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },
  updateCustomerProfileAccounts:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_CUSTOMER_PROFILE_ACCOUNTS,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateCustomerProfileDomains:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_CUSTOMER_PROFILE_DOMAINS,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateCustomerProfileUsers:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_CUSTOMER_PROFILE_USERS,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateCustomerProfilePayments:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_CUSTOMER_PROFILE_PAYMENTS,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateCustomerProfileStores:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_CUSTOMER_PROFILE_STORES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateTNSExplorerPreferencesExtra:
    (extraPreferences: IExtraPreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNS_EXPLORER_PREFERENCES_EXTRA,
        payload: extraPreferences,
      });
      dispatch(actionCreators.updateUserPreference());
    },

  updateEscalationsJobStatusPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_ESCALATIONS_JOB_STATUS_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateEscalationsJobReviewPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_ESCALATIONS_JOB_REVIEW_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateListManagerSeedTokenJobStatusPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_LISTMANAGER_SEEDTOKEN_JOB_STATUS_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateMismatchPackagesPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_MISMATCH_PACAKGES_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateMismatchReviewPackagePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_MISMATCH_REVIEW_PACAKGE_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateMismatchReviewPackagePreferencesExtra:
    (extraPreferences: IExtraPreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_MISMATCH_REVIEW_PACKAGE_PREFERENCES_EXTRA,
        payload: extraPreferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateTNSEnforcmentHistoryPreferences:
    (extraPreferences: IExtraPreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSENFORCEMENT_HISTORY_PREFERENCES,
        payload: extraPreferences,
      });
      dispatch(actionCreators.updateUserPreference());
    },

  updateTNSDetectionHistoryPreferences:
    (extraPreferences: IExtraPreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_TNSDETECTION_HISTORY_PREFERENCES,
        payload: extraPreferences,
      });
      dispatch(actionCreators.updateUserPreference());
    },

  updateCustomerDecisionHistoryPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_CUSTOMER_DECISION_HISTORY,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateCustomerDetectionHistoryPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_CUSTOMER_DETECTION_HISTORY,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateCustomerAIVDataPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_CUSTOMER_AIV_DATA,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },
  updateRejectionPackagesPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_REJECTION_PACAKGES_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateRejectionReviewPackagePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_REJECTION_REVIEW_PACAKGE_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateRejectionReviewPackagePreferencesExtra:
    (extraPreferences: IExtraPreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_REJECTION_REVIEW_PACKAGE_PREFERENCES_EXTRA,
        payload: extraPreferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateQREReviewsPackagesPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_QREREVIEWS_PACAKGES_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateQREReviewsReviewPackagePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_QREREVIEWS_REVIEW_PACAKGE_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateQREReviewsReviewPackagePreferencesExtra:
    (extraPreferences: IExtraPreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_QREREVIEWS_REVIEW_PACKAGE_PREFERENCES_EXTRA,
        payload: extraPreferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateSearchRulePreferences:
    (preferences: IUserModulePreferences, ruleType: IDropdownOption) =>
    async (dispatch: any) => {
      switch (ruleType?.key) {
        case 1:
          dispatch({
            type: actionTypes.UPDATE_SEARCH_DEFAULT_RULE_PREFERENCES,
            payload: preferences,
          });
          break;
        case 2:
          dispatch({
            type: actionTypes.UPDATE_SEARCH_TERM_OVERRIDE_PREFERENCES,
            payload: preferences,
          });
          break;
        case 3:
          dispatch({
            type: actionTypes.UPDATE_SEARCH_FLAG_OVERRIDE_PREFERENCES,
            payload: preferences,
          });
          break;
        default:
          break;
      }
      dispatch(actionCreators.updateUserPreference());
    },

  updateSearchUrlRulePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_SEARCH_URL_RULE_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateETFlagPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_ET_FLAG_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateETCategoryPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_ET_CATEGORY_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateETStatusPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_ET_STATUS_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateETHistoryPreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_ET_HISTORY_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateETFAOverridePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_ET_FA_OVERRIDE_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateETRuleOverridePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_ET_RULE_OVERRIDE_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateETCustPilotFeaturePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_ET_CUST_PILOT_FEATURE_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },

  updateETEditorialCustPilotFeaturePreferences:
    (preferences: IUserModulePreferences) => async (dispatch: any) => {
      dispatch({
        type: actionTypes.UPDATE_ET_EDITORIAL_CUST_PILOT_FEATURE_PREFERENCES,
        payload: preferences,
      });

      dispatch(actionCreators.updateUserPreference());
    },
};
