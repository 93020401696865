import { Reducer } from "redux";
import { Conditions } from "../../components/common/table/table-command-bar/plugins/filters/Filters.types";
import {
  IFilter,
  IModuleFilters,
  ITablePresets,
} from "../../components/common/table/Table.types";
import { LoadState, WorkflowStatus } from "@trinity/enums";
import { getApplicationConfig } from "../../utils/common/common";
import * as UserPreferenceActions from "./UserPreferenceActions";
import {
  DefaultUserPreferenceState,
  IEntityTrackerPreferences,
  ITnsEnforcementPreferences,
  ITnsExplorePreferences,
  IUserPreferenceState,
  UserPreferencesModule,
  ITnsDetectionPreferences,
} from "./UserPreferenceState";

export const userPreferenceReducer: Reducer<IUserPreferenceState> = (
  state: IUserPreferenceState = DefaultUserPreferenceState,
  action: any
) => {
  const tnsPreferences =
    (state.modules[
      `${UserPreferencesModule.TNS_EXPLORE}`
    ] as ITnsExplorePreferences) || {};

  const tnsEnforcementPreferences =
    (state.modules[
      `${UserPreferencesModule.TNS_ENFORCEMENT}`
    ] as ITnsEnforcementPreferences) || {};

  const tnsDetectionPreferences =
    (state.modules[
      `${UserPreferencesModule.TNS_DETECTION}`
    ] as ITnsDetectionPreferences) || {};

  // const tnsAIVPreferences =
  // (state.modules[
  //   `${UserPreferencesModule.TNS_AIV}`
  // ] as ITnsAIVPreferences) || {};

  const entityTrackerPreferences =
    (state.modules[
      `${UserPreferencesModule.ENTITY_TRACKER}`
    ] as IEntityTrackerPreferences) || {};

  switch (action.type) {
    case UserPreferenceActions.actionTypes.GET_USER_PREFERENCE:
      return {
        ...state,
        loadState: LoadState.InProgress,
      };

    case UserPreferenceActions.actionTypes.USER_PREFERENCE_RECEIVED:
      return {
        ...state,
        ...action.payload,
        loadState: LoadState.Loaded,
        app: {
          ...state.app,
          ...action.payload.app,
        },
        modules: {
          ...state.modules,
          ...action.payload.modules,
        },
        version: getApplicationConfig().appVersion,
      };

    case UserPreferenceActions.actionTypes.USER_PREFERENCE_FETCH_FAILED:
      return {
        ...state,
        loadState: LoadState.Loaded,
      };

    case UserPreferenceActions.actionTypes.UPDATE_APP_PREFERENCES:
      return {
        ...state,
        app: {
          ...state.app,
          skipTraining:
            "skipTraining" in action.payload
              ? action.payload.skipTraining
              : state.app.skipTraining,
        },
      };

    case UserPreferenceActions.actionTypes.RESET_APP_PREFERENCES:
      return {
        ...state,
        app: {
          ...state.app,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_USER_MANAGEMENT_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "ADMIN-USER-MANAGEMENT": {
            tablePresets: {
              ...state.modules["ADMIN-USER-MANAGEMENT"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_SEARCH_TRADEMARK_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "SEARCH-TRADEMARK": {
            tablePresets: {
              ...state.modules["SEARCH-TRADEMARK"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };
    case UserPreferenceActions.actionTypes.UPDATE_IMPORT_TRADEMARK_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "IMPORT-TRADEMARK": {
            tablePresets: {
              ...state.modules["IMPORT-TRADEMARK"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };
    case UserPreferenceActions.actionTypes.UPDATE_SEARCH_DSA_BLOCK_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "SEARCH-DSA-BLOCK": {
            tablePresets: {
              ...state.modules["SEARCH-DSA-BLOCK"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };
    case UserPreferenceActions.actionTypes
      .UPDATE_SEARCH_ENTITY_LIST_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "SEARCH-ENTITY-LIST": {
            tablePresets: {
              ...state.modules["SEARCH-ENTITY-LIST"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };
    case UserPreferenceActions.actionTypes.UPDATE_SEARCH_PLA_BLOCK_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "SEARCH-PLA-BLOCK": {
            tablePresets: {
              ...state.modules["SEARCH-PLA-BLOCK"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };
    case UserPreferenceActions.actionTypes.UPDATE_SEARCH_QRE_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "SEARCH-QRE": {
            tablePresets: {
              ...state.modules["SEARCH-QRE"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_SEARCH_PUBLISHER_RULE_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "SEARCH-PUBLISHER-RULE": {
            tablePresets: {
              ...state.modules["SEARCH-PUBLISHER-RULE"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_REVIEWER_REVIEW_PACKAGE_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "REVIEWER-REVIEW-PACKAGE": {
            tablePresets: {
              ...state.modules["REVIEWER-REVIEW-PACKAGE"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_REVIEWER_REVIEW_PACKAGE_PREFERENCES_EXTRA:
      return {
        ...state,
        modules: {
          ...state.modules,
          "REVIEWER-REVIEW-PACKAGE": {
            tablePresets: state.modules["REVIEWER-REVIEW-PACKAGE"].tablePresets,
            extra: {
              ...state.modules["REVIEWER-REVIEW-PACKAGE"].extra,
              ...action.payload,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_REVIEWER_SUBMITTED_PACKAGES_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "REVIEWER-SUBMITTED-PACKAGES": {
            tablePresets: {
              ...state.modules["REVIEWER-SUBMITTED-PACKAGES"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_PACKAGE_MANAGEMENT_PREFERENCES: {
      const statePresets =
        state.modules["ADMIN-PACKAGE-MANAGEMENT"].tablePresets || {};
      const payloadPresets: ITablePresets = action.payload.tablePresets || {};

      const appliedFilters: IFilter[] = [
        ...((payloadPresets.filters && payloadPresets.filters.applied) ||
          (statePresets.filters && statePresets.filters.applied) ||
          []),
      ]
        .filter((item) => item.name !== "defaultFilter")
        .concat([
          {
            key: [WorkflowStatus.Synced],
            field: "WorkflowStatusId",
            condition: Conditions.DOES_NOT_EQUAL,
            value: "",
            name: "defaultFilter",
            type: "checkbox",
            customQueries: [],
            isHidden: true,
          },
        ]);

      const moduleFilters: IModuleFilters = {
        ...(payloadPresets.filters ||
          statePresets.filters || {
            recent: [],
            saved: [],
          }),
        applied: appliedFilters,
      };

      return {
        ...state,
        modules: {
          ...state.modules,
          "ADMIN-PACKAGE-MANAGEMENT": {
            tablePresets: {
              ...state.modules["ADMIN-PACKAGE-MANAGEMENT"].tablePresets,
              ...action.payload.tablePresets,
              filters: moduleFilters,
            },
          },
        },
      };
    }

    case UserPreferenceActions.actionTypes
      .UPDATE_PACKAGE_MANAGEMENT_SUBMITTED_ONLY_PREFERENCES: {
      const statePresets =
        state.modules["ADMIN-PACKAGE-MANAGEMENT-SUBMITTED-ONLY"].tablePresets ||
        {};
      const payloadPresets = action.payload.tablePresets || {};

      const appliedFilters: IFilter[] = [
        ...((payloadPresets.filters && payloadPresets.filters.applied) ||
          (statePresets.filters && statePresets.filters.applied) ||
          []),
      ]
        .filter((item) => item.name !== "defaultFilter")
        .concat([
          {
            key: [WorkflowStatus.Submitted, WorkflowStatus.Synced],
            field: "WorkflowStatusId",
            condition: Conditions.EQUALS,
            value: "",
            name: "defaultFilter",
            type: "checkbox",
            customQueries: [],
            isHidden: true,
          },
        ]);

      const moduleFilters: IModuleFilters = {
        ...(payloadPresets.filters ||
          statePresets.filters || {
            recent: [],
            saved: [],
          }),
        applied: appliedFilters,
      };

      return {
        ...state,
        modules: {
          ...state.modules,
          "ADMIN-PACKAGE-MANAGEMENT-SUBMITTED-ONLY": {
            tablePresets: {
              ...state.modules["ADMIN-PACKAGE-MANAGEMENT-SUBMITTED-ONLY"]
                .tablePresets,
              ...action.payload.tablePresets,
              filters: moduleFilters,
            },
          },
        },
      };
    }

    case UserPreferenceActions.actionTypes.UPDATE_QUEUE_MANAGEMENT_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "ADMIN-QUEUE-MANAGEMENT": {
            tablePresets: {
              ...state.modules["ADMIN-QUEUE-MANAGEMENT"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_CUSTOMERS:
      const customerPreferences = tnsPreferences.customer || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            customer: {
              tablePresets: {
                ...(customerPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(customerPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_INSIGHTS_EXAMINE:
      const insightsPreferences = tnsPreferences.customer || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            insightsExaminePanel: {
              tablePresets: {
                ...(insightsPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(insightsPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_INSIGHTS_CARDS:
      const insightsCardsPreferences = tnsPreferences.insightsCardPanel || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            insightsCardPanel: {
              tablePresets: {
                ...(insightsCardsPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(insightsCardsPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_NETWORK_EXAMINE:
      const networkExaminePref = tnsPreferences.networkViewExaminePanel || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            networkViewExaminePanel: {
              tablePresets: {
                ...(networkExaminePref.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(networkExaminePref.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_ACCOUNTS:
      const accountPreferences = tnsPreferences.account || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            account: {
              tablePresets: {
                ...(accountPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(accountPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_STORES:
      const storesPreferences = tnsPreferences.stores || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            stores: {
              tablePresets: {
                ...(storesPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(storesPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_USERS:
      const usersPreferences = tnsPreferences.users || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            users: {
              tablePresets: {
                ...(usersPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(usersPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_DOMAINS:
      const domainsPreferences = tnsPreferences.domains || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            domains: {
              tablePresets: {
                ...(domainsPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(domainsPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_INVESTIGATE:
      const investigatePreferences = tnsPreferences.investigateWidget || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            investigateWidget: {
              tablePresets: {
                ...(investigatePreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(investigatePreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_RISK_EXAMINE:
      const riskPreferences = tnsPreferences.customerRiskExaminePanel || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            customerRiskExaminePanel: {
              tablePresets: {
                ...(riskPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(riskPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_TNSEXPLORE_MCA_EXAMINE:
      const mcaPreferences = tnsPreferences.mcaExaminePanel || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            mcaExaminePanel: {
              tablePresets: {
                ...(mcaPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(mcaPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_CUSTOMER_PROFILE_ACCOUNTS:
      const profileAccountsPrefferences =
        tnsPreferences.customerProfileAccounts || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            customerProfileAccounts: {
              tablePresets: {
                ...(profileAccountsPrefferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(profileAccountsPrefferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_CUSTOMER_PROFILE_DOMAINS:
      const profileDomainsPreferences =
        tnsPreferences.customerProfileDomains || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            customerProfileDomains: {
              tablePresets: {
                ...(profileDomainsPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(profileDomainsPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_CUSTOMER_PROFILE_USERS:
      const profileUserPreferences = tnsPreferences.userWidgets || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            userWidgets: {
              tablePresets: {
                ...(profileUserPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(profileUserPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_CUSTOMER_PROFILE_PAYMENTS:
      const profilePaymentPreferences = tnsPreferences.paymentWidgets || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            paymentWidgets: {
              tablePresets: {
                ...(profilePaymentPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(profilePaymentPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_CUSTOMER_PROFILE_STORES:
      const profileStorePreferences = tnsPreferences.storeWidget || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            storeWidget: {
              tablePresets: {
                ...(profileStorePreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(profileStorePreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_TNS_EXPLORER_PREFERENCES_EXTRA:
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            extra: {
              ...(tnsPreferences.extra || {}),
              ...(action.payload || {}),
            },
          } as ITnsExplorePreferences,
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_LISTMANAGER_SEEDTOKEN_JOB_STATUS_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "LISTMANAGER-SEEDTOKEN-JOB-STATUS": {
            tablePresets: {
              ...state.modules["LISTMANAGER-SEEDTOKEN-JOB-STATUS"].tablePresets,
              ...action.payload.tablePresets,
            },
            extra: {
              ...state.modules["LISTMANAGER-SEEDTOKEN-JOB-STATUS"].extra,
              ...action.payload.extra,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_ESCALATIONS_JOB_STATUS_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "ESCALATIONS-JOB-STATUS": {
            tablePresets: {
              ...state.modules["ESCALATIONS-JOB-STATUS"].tablePresets,
              ...action.payload.tablePresets,
            },
            extra: {
              ...state.modules["ESCALATIONS-JOB-STATUS"].extra,
              ...action.payload.extra,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_ESCALATIONS_JOB_REVIEW_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "ESCALATIONS-JOB-REVIEW": {
            tablePresets: {
              ...state.modules["ESCALATIONS-JOB-REVIEW"].tablePresets,
              ...action.payload.tablePresets,
            },
            extra: {
              ...state.modules["ESCALATIONS-JOB-REVIEW"].extra,
              ...action.payload.extra,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_MISMATCH_PACAKGES_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "MISMATCH-PACKAGES": {
            tablePresets: {
              ...state.modules["MISMATCH-PACKAGES"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_MISMATCH_REVIEW_PACAKGE_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "MISMATCH-REVIEW-PACKAGE": {
            tablePresets: {
              ...state.modules["MISMATCH-REVIEW-PACKAGE"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_MISMATCH_REVIEW_PACKAGE_PREFERENCES_EXTRA:
      return {
        ...state,
        modules: {
          ...state.modules,
          "MISMATCH-REVIEW-PACKAGE": {
            tablePresets: state.modules["MISMATCH-REVIEW-PACKAGE"].tablePresets,
            extra: {
              ...state.modules["MISMATCH-REVIEW-PACKAGE"].extra,
              ...action.payload,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_REJECTION_PACAKGES_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "REJECTION-PACKAGES": {
            tablePresets: {
              ...state.modules["REJECTION-PACKAGES"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_REJECTION_REVIEW_PACAKGE_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "REJECTION-REVIEW-PACKAGE": {
            tablePresets: {
              ...state.modules["REJECTION-REVIEW-PACKAGE"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_REJECTION_REVIEW_PACKAGE_PREFERENCES_EXTRA:
      return {
        ...state,
        modules: {
          ...state.modules,
          "REJECTION-REVIEW-PACKAGE": {
            tablePresets:
              state.modules["REJECTION-REVIEW-PACKAGE"].tablePresets,
            extra: {
              ...state.modules["REJECTION-REVIEW-PACKAGE"].extra,
              ...action.payload,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_QREREVIEWS_PACAKGES_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "QREREVIEWS-PACKAGES": {
            tablePresets: {
              ...state.modules["QREREVIEWS-PACKAGES"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_QREREVIEWS_REVIEW_PACAKGE_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "QREREVIEWS-REVIEW-PACKAGE": {
            tablePresets: {
              ...state.modules["QREREVIEWS-REVIEW-PACKAGE"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_QREREVIEWS_REVIEW_PACKAGE_PREFERENCES_EXTRA:
      return {
        ...state,
        modules: {
          ...state.modules,
          "QREREVIEWS-REVIEW-PACKAGE": {
            tablePresets:
              state.modules["QREREVIEWS-REVIEW-PACKAGE"].tablePresets,
            extra: {
              ...state.modules["QREREVIEWS-REVIEW-PACKAGE"].extra,
              ...action.payload,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_TNSDETECTION_HISTORY_PREFERENCES:
      const detectionHistoryPreferences =
        tnsDetectionPreferences.detectionHistory || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_DETECTION}`]: {
            ...tnsPreferences,
            detectionHistory: {
              tablePresets: {
                ...(detectionHistoryPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(detectionHistoryPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsDetectionPreferences,
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_TNSENFORCEMENT_HISTORY_PREFERENCES:
      const decisionHistoryPreferences =
        tnsEnforcementPreferences.decisionHistory || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_ENFORCEMENT}`]: {
            ...tnsPreferences,
            decisionHistory: {
              tablePresets: {
                ...(decisionHistoryPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(decisionHistoryPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsEnforcementPreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_CUSTOMER_DECISION_HISTORY:
      const customerDecisionPreferences = tnsPreferences.decisionHistory || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.TNS_EXPLORE}`]: {
            ...tnsPreferences,
            decisionHistory: {
              tablePresets: {
                ...(customerDecisionPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(customerDecisionPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as ITnsExplorePreferences,
        },
      };
    case UserPreferenceActions.actionTypes
      .UPDATE_SEARCH_DEFAULT_RULE_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "SEARCH-DEFAULT-RULE": {
            tablePresets: {
              ...state.modules["SEARCH-DEFAULT-RULE"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };
    case UserPreferenceActions.actionTypes
      .UPDATE_SEARCH_TERM_OVERRIDE_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "SEARCH-TERM-OVERRIDE": {
            tablePresets: {
              ...state.modules["SEARCH-TERM-OVERRIDE"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };
    case UserPreferenceActions.actionTypes
      .UPDATE_SEARCH_FLAG_OVERRIDE_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "SEARCH-FLAG-OVERRIDE": {
            tablePresets: {
              ...state.modules["SEARCH-FLAG-OVERRIDE"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };
    case UserPreferenceActions.actionTypes.UPDATE_SEARCH_URL_RULE_PREFERENCES:
      return {
        ...state,
        modules: {
          ...state.modules,
          "SEARCH-URL": {
            tablePresets: {
              ...state.modules["SEARCH-URL"].tablePresets,
              ...action.payload.tablePresets,
            },
          },
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_ET_FLAG_PREFERENCES:
      const flagPreferences = entityTrackerPreferences.flag || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.ENTITY_TRACKER}`]: {
            ...entityTrackerPreferences,
            flag: {
              tablePresets: {
                ...(flagPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(flagPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as IEntityTrackerPreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_ET_CATEGORY_PREFERENCES:
      const categoryPreferences = entityTrackerPreferences.category || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.ENTITY_TRACKER}`]: {
            ...entityTrackerPreferences,
            category: {
              tablePresets: {
                ...(categoryPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(categoryPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as IEntityTrackerPreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_ET_STATUS_PREFERENCES:
      const statusPreferences = entityTrackerPreferences.status || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.ENTITY_TRACKER}`]: {
            ...entityTrackerPreferences,
            status: {
              tablePresets: {
                ...(statusPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(statusPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as IEntityTrackerPreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_ET_HISTORY_PREFERENCES:
      const historyPreferences = entityTrackerPreferences.entityHistory || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.ENTITY_TRACKER}`]: {
            ...entityTrackerPreferences,
            entityHistory: {
              tablePresets: {
                ...(historyPreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(historyPreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as IEntityTrackerPreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_ET_FA_OVERRIDE_PREFERENCES:
      const faOverridePreferences =
        entityTrackerPreferences.flagLevelOverride || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.ENTITY_TRACKER}`]: {
            ...entityTrackerPreferences,
            flagLevelOverride: {
              tablePresets: {
                ...(faOverridePreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(faOverridePreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as IEntityTrackerPreferences,
        },
      };

    case UserPreferenceActions.actionTypes.UPDATE_ET_RULE_OVERRIDE_PREFERENCES:
      const ruleOverridePreferences =
        entityTrackerPreferences.ruleLevelOverride || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.ENTITY_TRACKER}`]: {
            ...entityTrackerPreferences,
            ruleLevelOverride: {
              tablePresets: {
                ...(ruleOverridePreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(ruleOverridePreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as IEntityTrackerPreferences,
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_ET_CUST_PILOT_FEATURE_PREFERENCES:
      const custPilotFeaturePreferences =
        entityTrackerPreferences.CustomerPilotFeature || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.ENTITY_TRACKER}`]: {
            ...entityTrackerPreferences,
            CustomerPilotFeature: {
              tablePresets: {
                ...(custPilotFeaturePreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(custPilotFeaturePreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as IEntityTrackerPreferences,
        },
      };

    case UserPreferenceActions.actionTypes
      .UPDATE_ET_EDITORIAL_CUST_PILOT_FEATURE_PREFERENCES:
      const editorialCustPilotFeaturePreferences =
        entityTrackerPreferences.EditorialCustomerPilotFeature || {};
      return {
        ...state,
        modules: {
          ...state.modules,
          [`${UserPreferencesModule.ENTITY_TRACKER}`]: {
            ...entityTrackerPreferences,
            EditorialCustomerPilotFeature: {
              tablePresets: {
                ...(editorialCustPilotFeaturePreferences.tablePresets || {}),
                ...(action.payload.tablePresets || {}),
              },
              extra: {
                ...(editorialCustPilotFeaturePreferences.extra || {}),
                ...(action.payload.extra || {}),
              },
            },
          } as IEntityTrackerPreferences,
        },
      };

    default:
      return state || DefaultUserPreferenceState;
  }
};
