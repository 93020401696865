export enum UserRole {
  Developer = -1,
  EditorialAdmin = 0,
  EditorialManager = 1,
  EditorialReviewer = 2,
  EditorialAdvancedReviewer = 3,
  EditorialListManager = 4,
  RiskViewer = 5,
  RiskReviewer = 6,
  RiskPremiumReviewer = 7,
  RiskAdmin = 8,
  EditorialReadOnly = 9,
  EditorialChineseAdmin = 10,
}
