import React, { lazy } from "react";
import { Route, Routes, Router } from "react-router-dom";
import EmptyLayout from "../components/layout/EmptyLayout";
import { UserRole } from "@trinity/enums";
import history from "../utils/common/history";
import {
  admin_auth,
  dsa_auth,
  enforcement_auth,
  entityList_auth,
  plaBlock_auth,
  publisherRule_auth,
  qre_auth,
  reviewer_auth,
  rule_create_auth,
  rule_search_auth,
  token_auth,
  trademark_auth,
} from "./authorization";
import RouteWrapper from "./RouteWrapper";
import LazyComponentWrapper from "./LazyComponentWrapper";

const NotFound = lazy(() => import("../components/error/NotFound"));
const AdminOverview = lazy(
  () => import("../modules/admin-overview/AdminOverview")
);
const AdminQueueManagement = lazy(
  () => import("../modules/admin-queue-management/AdminQueueManagement")
);
const AdminUserManagement = lazy(
  () => import("../modules/admin-user-management/AdminUserManagement")
);
const EscalationsJobReview = lazy(
  () => import("../modules/escalations-job-review/EscalationsJobReview")
);
const EscalationsJobStatus = lazy(
  () => import("../modules/escalations-job-status/EscalationsJobStatus")
);
const Escalations = lazy(() => import("../modules/escalations/Escalations"));
const ReviewerOverview = lazy(
  () => import("../modules/reviewer-overview/ReviewerOverview")
);
const ReviewerReviewPackage = lazy(
  () => import("../modules/reviewer-review-package/ReviewerReviewPackage")
);

const TNSExplore = lazy(() => import("../modules/tns-explore/TNSExplore"));
const TNSExploreCustomerProfile = lazy(
  () =>
    import(
      "../modules/tns-explore/tns-explore-customer-profile/TNSExploreCustomerProfile"
    )
);
const Welcome = lazy(() => import("../modules/welcome/Welcome"));
const SeedTokenDiscovery = lazy(
  () => import("../modules/list-manager-seed-token/SeedTokenDiscovery")
);
const SeedTokenJobStatus = lazy(
  () =>
    import("../modules/list-manager-seed-token-job-status/SeedTokenJobStatus")
);
const NormalizedTokenCheck = lazy(
  () => import("../modules/list-manager-normalized-token/NormalizedTokenCheck")
);
const WordBreaker = lazy(() => import("../modules/word-breaker/WordBreaker"));
const ReviewerSubmittedPackages = lazy(
  () =>
    import("../modules/reviewer-submitted-packages/ReviewerSubmittedPackages")
);
const MismatchReport = lazy(
  () => import("../modules/admin-mismatch-report/MismatchReport")
);
const AdminMismatchReportReview = lazy(
  () =>
    import("../modules/admin-mismatch-report-review/AdminMismatchReportReview")
);
const RejectionReport = lazy(
  () => import("../modules/admin-rejection-report/RejectionReport")
);
const AdminRejectionReportReview = lazy(
  () =>
    import(
      "../modules/admin-rejection-report-review/AdminRejectionReportReview"
    )
);
const QREReviewsReport = lazy(
  () => import("../modules/admin-qre-reviews-report/QREReviewsReport")
);
const AdminQREReviewsReportReview = lazy(
  () =>
    import(
      "../modules/admin-qre-reviews-report-review/AdminQREReviewsReportReview"
    )
);
const SearchTrademark = lazy(
  () => import("../modules/trademark/search-trademark/SearchTrademark")
);
const ImportTrademark = lazy(
  () => import("../modules/trademark/import-trademark/ImportTrademark")
);
const UpdateTrademark = lazy(
  () => import("../modules/trademark/update-trademark/UpdateTrademark")
);
const DeleteExistingTrademarkFinal = lazy(
  () => import("../modules/trademark/delete-trademark/DeleteTrademark")
);
const CreateTrademark = lazy(
  () => import("../modules/trademark/create-trademark/CreateTrademark")
);
const AdminPackageManagement = lazy(
  () => import("../modules/admin-package-management/AdminPackageManagement")
);
const CreateTrademarkAddNewRules = lazy(
  () =>
    import("../modules/trademark/create-trademark/CreateTrademarkAddNewRules")
);
const UpdateExistingTrademarkFinal = lazy(
  () =>
    import("../modules/trademark/update-trademark/UpdateExistingTrademarkFinal")
);
const CreateQre = lazy(() => import("../modules/qre/create-qre/CreateQre"));
const SearchQre = lazy(() => import("../modules/qre/search-qre/SearchQre"));
const UpdateQre = lazy(() => import("../modules/qre/update-qre/UpdateQre"));
const CreateDsaBlock = lazy(
  () => import("../modules/dsa-block/create-dsa-block/CreateDsaBlock")
);
const SearchDsaBlock = lazy(
  () => import("../modules/dsa-block/search-dsa-block/SearchDsaBlock")
);
const CreatePlaBlock = lazy(
  () => import("../modules/pla-block/create-pla-block/CreatePlaBlock")
);
const SearchPlaBlock = lazy(
  () => import("../modules/pla-block/search-pla-block/SearchPlaBlock")
);
const TnsEnforcement = lazy(
  () => import("../modules/tns-enforcement/TnsEnforcement")
);
const TNSEnforcementHistory = lazy(
  () => import("../modules/tns-enforcement-history/TNSEnforcementHistory")
);
const TNSDetectionHistory = lazy(
  () => import("../modules/tns-detection-history/TNSDetectionHistory")
);
const CreateRule = lazy(() => import("../modules/rule/create-rule/CreateRule"));
const CreateUrl = lazy(
  () => import("../modules/rule-url/create-url/CreateUrl")
);
const SearchRule = lazy(() => import("../modules/rule/search-rule/SearchRule"));
const SearchUrl = lazy(
  () => import("../modules/rule-url/search-url/SearchUrl")
);
const UpdateUrl = lazy(
  () => import("../modules/rule-url/update-url/UpdateUrl")
);
const UpdateRule = lazy(() => import("../modules/rule/update-rule/UpdateRule"));
const CreatePublisherRule = lazy(
  () =>
    import(
      "../modules/publisher-rule/create-publisher-rule/CreatePublisherRule"
    )
);
const SearchPublisherRule = lazy(
  () =>
    import(
      "../modules/publisher-rule/search-publisher-rule/SearchPublisherRule"
    )
);
const UpdatePublisherRule = lazy(
  () =>
    import(
      "../modules/publisher-rule/update-publisher-rule/UpdatePublisherRule"
    )
);
const CreateEntityList = lazy(
  () => import("../modules/entity-list/create-entity-list/CreateEntityList")
);
const SearchEntityList = lazy(
  () => import("../modules/entity-list/search-entity-list/SearchEntityList")
);

const EntityTracker = lazy(
  () => import("../modules/editorial-tools/entity-tracker/EntityTracker")
);

const routes = [
  // reviewer
  {
    path: "/reviewer/overview",
    element: ReviewerOverview,
    userRoles: reviewer_auth,
  },
  {
    path: "/reviewer/review-package",
    element: ReviewerReviewPackage,
    userRoles: [...reviewer_auth, UserRole.EditorialReadOnly],
  },
  {
    path: "/reviewer/review-package/view/:packageGuid",
    element: ReviewerReviewPackage,
    userRoles: [...reviewer_auth, UserRole.EditorialReadOnly],
  },
  {
    path: "/reviewer/submitted-packages/:queueId",
    element: ReviewerSubmittedPackages,
    userRoles: reviewer_auth,
  },
  // admin
  {
    path: "/admin/overview",
    element: AdminOverview,
    userRoles: [...admin_auth, UserRole.EditorialReadOnly],
  },
  {
    path: "/admin/user-management",
    element: AdminUserManagement,
    userRoles: [...admin_auth, UserRole.EditorialReadOnly, UserRole.RiskAdmin],
  },
  {
    path: "/admin/queue-management",
    element: AdminQueueManagement,
    userRoles: [...admin_auth, UserRole.EditorialReadOnly],
  },
  {
    path: "/admin/queue-management/:queueId",
    element: AdminPackageManagement,
    userRoles: [...admin_auth, UserRole.EditorialReadOnly],
  },
  {
    path: "/admin/mismatch",
    element: MismatchReport,
    userRoles: admin_auth,
  },
  {
    path: "/admin/mismatch/review-package",
    element: AdminMismatchReportReview,
    userRoles: admin_auth,
  },
  {
    path: "/admin/mismatch/review-package/view/:packageGuid",
    element: AdminMismatchReportReview,
    userRoles: admin_auth,
  },
  {
    path: "/admin/rejection",
    element: RejectionReport,
    userRoles: admin_auth,
  },
  {
    path: "/admin/rejection/review-package",
    element: AdminRejectionReportReview,
    userRoles: admin_auth,
  },
  {
    path: "/admin/rejection/review-package/view/:packageGuid",
    element: AdminRejectionReportReview,
    userRoles: admin_auth,
  },
  {
    path: "/admin/qre-reviews",
    element: QREReviewsReport,
    userRoles: admin_auth,
  },
  {
    path: "/admin/qre-reviews/review-package",
    element: AdminQREReviewsReportReview,
    userRoles: admin_auth,
  },
  {
    path: "/admin/qre-reviews/review-package/view/:packageGuid",
    element: AdminQREReviewsReportReview,
    userRoles: admin_auth,
  },
  // tns
  {
    path: "/tns/explore",
    element: TNSExplore,
    userRoles: enforcement_auth,
  },
  {
    path: "/tns/explore/:customerId/customerprofile",
    element: TNSExploreCustomerProfile,
    userRoles: enforcement_auth,
  },
  // enforcement
  {
    path: "/enforcement/entity-enforcement",
    element: TnsEnforcement,
    userRoles: enforcement_auth,
  },
  {
    path: "/enforcement/entity-enforcement-history",
    element: TNSEnforcementHistory,
    userRoles: enforcement_auth,
  },
  //detection
  {
    path: "/enforcement/entity-detection-history",
    element: TNSDetectionHistory,
    userRoles: enforcement_auth,
  },
  // escalations
  {
    path: "/escalations",
    element: Escalations,
    userRoles: [
      UserRole.EditorialAdmin,
      UserRole.EditorialChineseAdmin,
      UserRole.EditorialReviewer,
      UserRole.EditorialReadOnly,
      UserRole.RiskAdmin,
      UserRole.RiskReviewer,
      UserRole.RiskPremiumReviewer,
    ],
  },
  {
    path: "/escalations/job-status",
    element: EscalationsJobStatus,
    userRoles: [
      UserRole.EditorialAdmin,
      UserRole.EditorialChineseAdmin,
      UserRole.EditorialReviewer,
      UserRole.EditorialReadOnly,
      UserRole.RiskAdmin,
      UserRole.RiskReviewer,
      UserRole.RiskPremiumReviewer,
    ],
  },
  {
    path: "/escalations/job-status/review/:jobName/:packageGuid",
    element: EscalationsJobReview,
    userRoles: [
      UserRole.EditorialAdmin,
      UserRole.EditorialChineseAdmin,
      UserRole.EditorialReviewer,
      UserRole.EditorialReadOnly,
    ],
  },
  // qre
  {
    path: "/qre/createQre",
    element: CreateQre,
    userRoles: qre_auth,
  },
  {
    path: "/qre/searchQre",
    element: SearchQre,
    userRoles: [
      ...qre_auth,
      UserRole.EditorialReadOnly,
      UserRole.EditorialReviewer,
      UserRole.EditorialManager,
    ],
  },
  {
    path: "/qre/updateQre",
    element: UpdateQre,
    userRoles: qre_auth,
  },
  // token
  {
    path: "/listmanager/seed-token-discovery",
    element: SeedTokenDiscovery,
    userRoles: token_auth,
  },
  {
    path: "/listmanager/seed-token-job-status",
    element: SeedTokenJobStatus,
    userRoles: token_auth,
  },
  {
    path: "/listmanager/normalized-token-check",
    element: NormalizedTokenCheck,
    userRoles: token_auth,
  },
  {
    path: "/listmanager/word-breaker",
    element: WordBreaker,
    userRoles: token_auth,
  },
  // trademark
  {
    path: "/listmanager/createTrademark",
    element: CreateTrademark,
    userRoles: trademark_auth,
  },
  {
    path: "/listmanager/createTrademarkFinal",
    element: CreateTrademarkAddNewRules,
    userRoles: trademark_auth,
  },
  {
    path: "/listmanager/updateTrademark",
    element: UpdateTrademark,
    userRoles: trademark_auth,
  },
  {
    path: "/listmanager/updateTrademarkFinal",
    element: UpdateExistingTrademarkFinal,
    userRoles: trademark_auth,
  },
  {
    path: "/listmanager/deleteTrademark",
    element: DeleteExistingTrademarkFinal,
    userRoles: trademark_auth,
  },
  {
    path: "/listmanager/searchTrademark",
    element: SearchTrademark,
    userRoles: [
      ...trademark_auth,
      UserRole.EditorialReadOnly,
      UserRole.EditorialReviewer,
      UserRole.EditorialManager,
    ],
  },
  {
    path: "/listmanager/importTrademark", // not in tip
    element: ImportTrademark,
    userRoles: [
      UserRole.EditorialAdmin,
      UserRole.EditorialReviewer,
      UserRole.RiskAdmin,
      UserRole.EditorialChineseAdmin,
    ],
  },
  // dsa
  {
    path: "/listmanager/createDsaBlock",
    element: CreateDsaBlock,
    userRoles: dsa_auth,
  },
  {
    path: "/listmanager/searchDsaBlock",
    element: SearchDsaBlock,
    userRoles: dsa_auth,
  },
  // entity list
  {
    path: "/listmanager/createEntityList",
    element: CreateEntityList,
    userRoles: entityList_auth,
  },
  {
    path: "/listmanager/searchEntityList",
    element: SearchEntityList,
    userRoles: entityList_auth,
  },
  // pla
  {
    path: "/listmanager/createPlaBlock",
    element: CreatePlaBlock,
    userRoles: plaBlock_auth,
  },
  {
    path: "/listmanager/searchPlaBlock",
    element: SearchPlaBlock,
    userRoles: plaBlock_auth,
  },
  // rule
  {
    path: "/rule/createRule",
    element: CreateRule,
    userRoles: rule_create_auth,
  },
  {
    path: "/rule/updateRule",
    element: UpdateRule,
    userRoles: rule_create_auth,
  },
  {
    path: "/rule/searchRule",
    element: SearchRule,
    userRoles: rule_search_auth,
  },
  // url
  {
    path: "/rule/createUrl",
    element: CreateUrl,
    userRoles: rule_create_auth,
  },
  {
    path: "/rule/updateUrlRule",
    element: UpdateUrl,
    userRoles: rule_create_auth,
  },
  {
    path: "/rule/searchUrl",
    element: SearchUrl,
    userRoles: rule_search_auth,
  },
  // entity-tracker
  {
    path: "/editorial/EntityTracker",
    element: EntityTracker,
    userRoles: [
      UserRole.EditorialAdmin,
      UserRole.EditorialManager,
      UserRole.EditorialReviewer,
      UserRole.EditorialAdvancedReviewer,
      UserRole.EditorialReadOnly,
      UserRole.RiskViewer,
      UserRole.RiskReviewer,
      UserRole.RiskPremiumReviewer,
      UserRole.RiskAdmin,
      UserRole.EditorialChineseAdmin,
      UserRole.Developer,
    ],
  },
  // publisher
  {
    path: "/publisher/createRule",
    element: CreatePublisherRule,
    userRoles: publisherRule_auth,
  },
  {
    path: "/publisher/searchRule",
    element: SearchPublisherRule,
    userRoles: publisherRule_auth,
  },
  {
    path: "/publisher/updateRule",
    element: UpdatePublisherRule,
    userRoles: publisherRule_auth,
  },
];

const RoutesComponent = () => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      <Routes>
        <Route
          path="/"
          element={
            <LazyComponentWrapper>
              <EmptyLayout>
                <Welcome />
              </EmptyLayout>
            </LazyComponentWrapper>
          }
        />
        {routes.map((item) => (
          <Route
            key={item.path}
            path={item.path}
            element={
              <RouteWrapper allowedRoles={item.userRoles}>
                <item.element />
              </RouteWrapper>
            }
          />
        ))}
        <Route
          path="*"
          element={
            <LazyComponentWrapper>
              <NotFound />
            </LazyComponentWrapper>
          }
        />
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
