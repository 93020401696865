import { Dropdown, IDropdownOption } from "@fluentui/react";
import {
  IRuleData,
  ImportRuleDataDetails,
} from "../../modules/rule/Rule.types";
import { configs } from "../../configs";
import { IUrlData } from "../../modules/rule-url/Url.types";

export enum actionTypes {
  INSERT_NEW_DEFAULT_TERM_RULE = "INSERT_NEW_DEFAULT_TERM_RULE",
  INSERT_NEW_DEFAULT_TERM_RULE_FAILED = "INSERT_NEW_DEFAULT_TERM_RULE_FAILED",
  SEARCH_DEFAULT_TERM_RULE = "SEARCH_DEFAULT_TERM_RULE",
  SEARCH_DEFAULT_TERM_RULE_FAILED = "SEARCH_DEFAULT_TERM_RULE_FAILED",
  UPDATE_DEFAULT_TERM_RULE = "UPDATE_DEFAULT_TERM_RULE",
  UPDATE_DEFAULT_TERM_RULE_FAILED = "UPDATE_DEFAULT_TERM_RULE_FAILED",
  DELETE_DEFAULT_TERM_RULE = "DELETE_DEFAULT_TERM_RULE",
  DELETE_DEFAULT_TERM_RULE_FAILED = "DELETE_DEFAULT_TERM_RULE_FAILED",
  IMPORT_DEFAULT_TERM_RULE = "IMPORT_DEFAULT_TERM_RULE",
  IMPORT_DEFAULT_TERM_RULE_FAILED = "IMPORT_DEFAULT_TERM_RULE_FAILED",
  SELECTED_DEFAULT_TERM_RULE = "SELECTED_DEFAULT_TERM_RULE",
  SELECTED_DEFAULT_TERM_RULE_FAILED = "SELECTED_DEFAULT_TERM_RULE_FAILED",
  ON_LOAD_DEFAULT_TERM_RULE_DATA = "ON_LOAD_DEFAULT_TERM_RULE_DATA",
  ON_LOAD_DEFAULT_TERM_RULE_DATA_FAILED = "ON_LOAD_DEFAULT_TERM_RULE_DATA_FAILED",
  INSERT_NEW_TERM_OVERRIDE_RULE = "INSERT_NEW_TERM_OVERRIDE_RULE",
  INSERT_NEW_TERM_OVERRIDE_RULE_FAILED = "INSERT_NEW_TERM_OVERRIDE_RULE_FAILED",
  SEARCH_TERM_OVERRIDE_RULE = "SEARCH_TERM_OVERRIDE_RULE",
  SEARCH_TERM_OVERRIDE_RULE_FAILED = "SEARCH_TERM_OVERRIDE_RULE_FAILED",
  UPDATE_TERM_OVERRIDE_RULE = "UPDATE_TERM_OVERRIDE_RULE",
  UPDATE_TERM_OVERRIDE_RULE_FAILED = "UPDATE_TERM_OVERRIDE_RULE_FAILED",
  DELETE_TERM_OVERRIDE_RULE = "DELETE_TERM_OVERRIDE_RULE",
  DELETE_TERM_OVERRIDE_RULE_FAILED = "DELETE_TERM_OVERRIDE_RULE_FAILED",
  IMPORT_TERM_OVERRIDE_RULE = "IMPORT_TERM_OVERRIDE_RULE",
  IMPORT_TERM_OVERRIDE_RULE_FAILED = "IMPORT_TERM_OVERRIDE_RULE_FAILED",
  SELECTED_TERM_OVERRIDE_RULE = "SELECTED_TERM_OVERRIDE_RULE",
  SELECTED_TERM_OVERRIDE_RULE_FAILED = "SELECTED_TERM_OVERRIDE_RULE_FAILED",
  ON_LOAD_TERM_OVERRIDE_RULE_DATA = "ON_LOAD_TERM_OVERRIDE_RULE_DATA",
  ON_LOAD_TERM_OVERRIDE_RULE_DATA_FAILED = "ON_LOAD_TERM_OVERRIDE_RULE_DATA_FAILED",
  INSERT_NEW_FLAG_OVERRIDE_RULE = "INSERT_NEW_FLAG_OVERRIDE_RULE",
  INSERT_NEW_FLAG_OVERRIDE_RULE_FAILED = "INSERT_NEW_FLAG_OVERRIDE_RULE_FAILED",
  SEARCH_FLAG_OVERRIDE_RULE = "SEARCH_FLAG_OVERRIDE_RULE",
  SEARCH_FLAG_OVERRIDE_RULE_FAILED = "SEARCH_FLAG_OVERRIDE_RULE_FAILED",
  UPDATE_FLAG_OVERRIDE_RULE = "UPDATE_FLAG_OVERRIDE_RULE",
  UPDATE_FLAG_OVERRIDE_RULE_FAILED = "UPDATE_FLAG_OVERRIDE_RULE_FAILED",
  DELETE_FLAG_OVERRIDE_RULE = "DELETE_FLAG_OVERRIDE_RULE",
  DELETE_FLAG_OVERRIDE_RULE_FAILED = "DELETE_FLAG_OVERRIDE_RULE_FAILED",
  IMPORT_FLAG_OVERRIDE_RULE = "IMPORT_FLAG_OVERRIDE_RULE",
  IMPORT_FLAG_OVERRIDE_RULE_FAILED = "IMPORT_FLAG_OVERRIDE_RULE_FAILED",
  SELECTED_FLAG_OVERRIDE_RULE = "SELECTED_FLAG_OVERRIDE_RULE",
  SELECTED_FLAG_OVERRIDE_RULE_FAILED = "SELECTED_FLAG_OVERRIDE_RULE_FAILED",
  ON_LOAD_FLAG_OVERRIDE_RULE_DATA = "ON_LOAD_FLAG_OVERRIDE_RULE_DATA",
  ON_LOAD_FLAG_OVERRIDE_RULE_DATA_FAILED = "ON_LOAD_FLAG_OVERRIDE_RULE_DATA_FAILED",
  INSERT_URL = "INSERT_URL",
  INSERT_URL_FAILED = "INSERT_URL_FAILED",
  SEARCH_URL = "SEARCH_URL",
  SEARCH_URL_FAILED = "SEARCH_URL_FAILED",
  UPDATE_URL = "UPDATE_URL",
  UPDATE_URL_FAILED = "UPDATE_URL_FAILED",
  DELETE_URL = "DELETE_URL",
  DELETE_URL_FAILED = "DELETE_URL_FAILED",
  IMPORT_URL_RULE = "IMPORT_URL_RULE",
  IMPORT_URL_RULE_FAILED = "IMPORT_URL_RULE_FAILED",
}

export const endpoints = {
  INSERT_NEW_DEFAULT_TERM_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/defaultTerm`,
  SEARCH_DEFAULT_TERM_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/searchDefaultTerm`,
  UPDATE_DEFAULT_TERM_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/defaultTerm`,
  DELETE_DEFAULT_TERM_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/defaultTerm`,
  INSERT_NEW_TERM_OVERRIDE_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/termOverride`,
  SEARCH_TERM_OVERRIDE_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/searchTermOverride`,
  UPDATE_TERM_OVERRIDE_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/termOverride`,
  DELETE_TERM_OVERRIDE_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/termOverride`,
  INSERT_NEW_FLAG_OVERRIDE_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/flagOverride`,
  SEARCH_FLAG_OVERRIDE_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/searchFlagOverride`,
  UPDATE_FLAG_OVERRIDE_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/flagOverride`,
  DELETE_FLAG_OVERRIDE_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/flagOverride`,
  INSERT_URL: `${configs.client.endpoint.trinityEditorialEndpoint}rule/url`,
  SEARCH_URL: `${configs.client.endpoint.trinityEditorialEndpoint}rule/searchUrl`,
  UPDATE_URL: `${configs.client.endpoint.trinityEditorialEndpoint}rule/url`,
  DELETE_URL: `${configs.client.endpoint.trinityEditorialEndpoint}rule/url`,
  IMPORT_DEFAULT_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/defaultTermRulesBulkImport`,
  IMPORT_TERM_OVERRIDE_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/termOverrideRulesBulkImport`,
  IMPORT_FLAG_OVERRIDE_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/flagOverrideRulesBulkImport`,
  IMPORT_URL_RULE: `${configs.client.endpoint.trinityEditorialEndpoint}rule/urlRulesBulkImport`,
};

// export const formatDate = (date: Date): string => {
//   const padZero = (value) => (value < 10 ? `0${value}` : value);
//   const formattedDate = `${date.getFullYear()}-${padZero(
//     date.getMonth() + 1
//   )}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(
//     date.getMinutes()
//   )}:${padZero(date.getSeconds())}.${date.getMilliseconds()}`;
//   return formattedDate;
// };
export interface IDefaultTermRule {
  language: number;
  hasGlobalCountryOverride: boolean | undefined;
  term: string;
  matchType: number;
  expiryDate: Date | undefined;
  id: number | undefined;
  defaultFlagAreaId: number;
  defaultFlagLevel: number;
  country: number;
  componentMask: number;
  componentMaskStr: string;
  modifiedByUser: string;
  modifiedDateTime: Date | undefined;
  reasonCode: string | null;
  comments: string;
}
export interface IDefaultTermImportRule {
  action: string;
  language: number;
  hasGlobalCountryOverride: boolean | undefined;
  term: string;
  matchType: number;
  expiryDate: Date | undefined;
  id: number | undefined;
  defaultFlagAreaId: number;
  defaultFlagLevel: number;
  country: number;
  componentMask: number;
  componentMaskStr: string;
  modifiedByUser: string;
  modifiedDateTime: Date | undefined | string;
  reasonCode: string | null;
  comments: string;
}

export const DefaultTermRule: IDefaultTermRule = {
  language: -1,
  hasGlobalCountryOverride: false,
  term: "",
  matchType: -1,
  expiryDate: undefined,
  id: undefined,
  defaultFlagAreaId: -1,
  defaultFlagLevel: -1,
  country: -1,
  componentMask: -1,
  componentMaskStr: "",
  modifiedByUser: "",
  modifiedDateTime: undefined,
  reasonCode: null,
  comments: "",
};

export interface ITermOverrideRule {
  term: string;
  id: number | undefined;
  advertiserId: number;
  advertiserName: string;
  overrideFlagAreaId: number;
  overrideFlagLevel: number;
  BypassType: number;
  matchType: number;
  country: number;
  language: number;
  source: number;
  modifiedByUser: string;
  modifiedDateTime: Date | undefined;
  comments: string;
}

// export interface ITermOverrideRuleForDelete {
//   id: number | undefined;
//   advertiserId: number;
//   overrideFlagAreaId: number;
//   overrideFlagLevel: number;
//   BypassType: number;
//   modifiedDateTime: Date | undefined;
// }

export interface ITermOverrideImportRule {
  action: string;
  term: string;
  id: number | undefined;
  advertiserId: number;
  advertiserName: string;
  overrideFlagAreaId: number;
  overrideFlagLevel: number;
  BypassType: number;
  matchType: number;
  country: number;
  language: number;
  source: number;
  modifiedByUser: string;
  modifiedDateTime: Date | undefined | string;
}

export const TermOverrideRule: ITermOverrideRule = {
  term: "",
  id: undefined,
  advertiserId: -1,
  advertiserName: "",
  matchType: -1,
  overrideFlagAreaId: -1,
  overrideFlagLevel: -1,
  BypassType: -1,
  country: -1,
  language: -1,
  source: -1,
  modifiedByUser: "",
  modifiedDateTime: undefined,
  comments: "",
};

export interface IFlagOverrideRule {
  id: number | undefined;
  defaultFlagAreaId: number;
  defaultFlagLevel: number;
  advertiserId: number;
  advertiserName: string;
  overrideFlagAreaId: number;
  overrideFlagLevel: number;
  BypassType: number;
  componentMask: number;
  componentMaskStr: string;
  country: number;
  modifiedByUser: string;
  modifiedDateTime: Date | undefined;
  comments: string;
}
export interface IFlagOverrideImportRule {
  action: string;
  id: number | undefined;
  defaultFlagAreaId: number;
  defaultFlagLevel: number;
  advertiserId: number;
  advertiserName: string;
  overrideFlagAreaId: number;
  overrideFlagLevel: number;
  BypassType: number;
  componentMask: number;
  componentMaskStr: string;
  country: number;
  modifiedByUser: string;
  modifiedDateTime: Date | undefined | string;
  comments: string;
}

export const FlagOverrideRule: IFlagOverrideRule = {
  id: undefined,
  defaultFlagAreaId: -1,
  defaultFlagLevel: -1,
  advertiserId: -1,
  advertiserName: "",
  overrideFlagAreaId: -1,
  overrideFlagLevel: -1,
  BypassType: -1,
  componentMask: -1,
  componentMaskStr: "",
  country: -1,
  modifiedByUser: "",
  modifiedDateTime: undefined,
  comments: "",
};

export interface IURLRule {
  term: string;
  matchType: number;
  id: number;
  defaultFlagAreaId: number;
  defaultFlagLevel: number;
  country: number;
  modifiedDateTime: Date | undefined;
  comments: string;
}

export interface IURLImportRule {
  action: string;
  term: string;
  matchType: number;
  id: number;
  defaultFlagAreaId: number;
  defaultFlagLevel: number;
  country: number;
  comments: string;
}

export interface IRules {
  rules: any[];
}

export const Rules: IRules = {
  rules: [],
};
export interface IDeleteWithCommentsRules {
  rules: any[];
  comments: string;
}

export const DeleteWithCommentsRules: IDeleteWithCommentsRules = {
  rules: [],
  comments: "",
};

export interface ICreateUpdateDefaultRule {
  rules: IDefaultTermRule[];
  isUpdate: boolean;
}

export const CreateUpdateDefaultRule: ICreateUpdateDefaultRule = {
  rules: [],
  isUpdate: false,
};

export interface IDeleteRules {
  ruleIds: number[];
}

export const DeleteRules: IDeleteRules = {
  ruleIds: [],
};

export const parseDropdownToString = (
  dropdowns: IDropdownOption[],
  isComponent = true
): string => {
  if (isComponent)
    return dropdowns
      .filter((d) => d.key !== "All")
      .map((d) => String(d.key))
      .join(",");
  return dropdowns.map((d) => String(d.key)).join(",");
};

const getValidKey = (data: IDropdownOption): number | undefined => {
  if (!data || (typeof data.key == "number" && data.key < 0)) return undefined;
  return typeof data.key == "string" ? parseInt(data.key) : data.key;
};

export const convertRuleDataToDefaultTermRule = (
  ruleData: IRuleData,
  userId: string
): IRules => {
  const defaultTermRule: IDefaultTermRule = {
    language:
      ruleData && ruleData.language
        ? typeof ruleData.language.key === "string"
          ? parseInt(ruleData.language.key)
          : ruleData.language.key
        : -1,
    hasGlobalCountryOverride: false,
    term: ruleData.term || "",
    matchType: Number(ruleData?.matchType?.key) || 0,
    expiryDate: undefined,
    id: ruleData.id > 0 ? ruleData.id : undefined,
    defaultFlagAreaId: ruleData.flagArea.key as number,
    defaultFlagLevel: ruleData.flagLevel.key as number,
    country: ruleData.country
      ? typeof ruleData.country.key === "string"
        ? parseInt(ruleData.country.key)
        : ruleData.country.key
      : -1,
    componentMask: 0,
    componentMaskStr:
      typeof ruleData.components === "string"
        ? ruleData.components
        : parseDropdownToString(ruleData.components),
    modifiedByUser: userId,
    modifiedDateTime:
      ruleData && ruleData.modifiedDateTime
        ? ruleData.modifiedDateTime
        : undefined,
    reasonCode: ruleData.reasonCode,
    comments: ruleData.comments || "",
  };

  const rules: IRules = { rules: [] };
  rules.rules.push(defaultTermRule);
  return rules;
};

export const convertRuleDataToTermOverrideRule = (
  ruleData: IRuleData,
  userId: string
): IRules => {
  const termOverride: ITermOverrideRule = {
    term: ruleData.term,
    id: ruleData.id > 0 ? ruleData.id : undefined,
    advertiserId: ruleData.advertiserName
      ? typeof ruleData.advertiserName.key == "string"
        ? parseInt(ruleData.advertiserName.key)
        : ruleData.advertiserName.key
      : -1,
    advertiserName: ruleData.advertiserName ? ruleData.advertiserName.name : "",
    matchType: Number(ruleData?.matchType?.key) ?? 0,
    overrideFlagAreaId: ruleData.overrideFlagArea.key as number,
    overrideFlagLevel: ruleData.overrideFlagLevel.key as number,
    BypassType:
      typeof ruleData.bypassType.key == "string"
        ? parseInt(ruleData.bypassType.key)
        : ruleData.bypassType.key,
    country:
      ruleData && ruleData.country
        ? typeof ruleData.country.key === "string"
          ? parseInt(ruleData.country.key)
          : ruleData.country.key
        : -1,
    language:
      ruleData && ruleData.language
        ? typeof ruleData.language.key === "string"
          ? parseInt(ruleData.language.key)
          : ruleData.language.key
        : -1,
    source: 0,
    modifiedByUser: userId,
    modifiedDateTime:
      ruleData && ruleData.modifiedDateTime
        ? ruleData.modifiedDateTime
        : undefined,
    comments: ruleData.comments || "",
  };
  const rules: IRules = { rules: [] };
  rules.rules.push(termOverride);
  return rules;
};

export const convertRuleDataToTermOverrideRuleForDelete = (
  ruleData: any[]
): IRules => {
  //const rules: IRules = { rules: [] };
  const newRules: IDeleteWithCommentsRules = { rules: [], comments: "" };
  ruleData.map((data) => {
    const {
      id,
      overrideFlagAreaId,
      overrideFlagLevel,
      BypassType,
      advertiserId,
      modifiedDateTime,
    } = data;

    const rule = {
      id: id && id > 0 ? id : undefined,
      advertiserId: advertiserId,
      overrideFlagAreaId: overrideFlagAreaId,
      overrideFlagLevel: overrideFlagLevel,
      BypassType: BypassType,
      modifiedDateTime: modifiedDateTime ? modifiedDateTime : undefined,
    };
    newRules.rules.push(rule);
  });
  newRules.comments = ruleData[0].Comments || "";
  return newRules;
};

export const convertRuleDataToFlagOverrideRule = (
  ruleData: IRuleData,
  userId: string
): IRules => {
  const flagOverride: IFlagOverrideRule = {
    id: ruleData.id > 0 ? ruleData.id : undefined,
    defaultFlagAreaId: ruleData.flagArea.key as number,
    defaultFlagLevel: ruleData.flagLevel.key as number,
    advertiserId: ruleData.advertiserName
      ? typeof ruleData.advertiserName.key == "string"
        ? parseInt(ruleData.advertiserName.key)
        : ruleData.advertiserName.key
      : -1,
    advertiserName: ruleData.advertiserName ? ruleData.advertiserName.name : "",
    overrideFlagAreaId: ruleData.overrideFlagArea.key as number,
    overrideFlagLevel: ruleData.overrideFlagLevel.key as number,
    BypassType:
      typeof ruleData.bypassType.key == "string"
        ? parseInt(ruleData.bypassType.key)
        : ruleData.bypassType.key,
    componentMask: 0,
    country:
      ruleData && ruleData.country
        ? typeof ruleData.country.key === "string"
          ? parseInt(ruleData.country.key)
          : ruleData.country.key
        : -1,
    componentMaskStr:
      typeof ruleData.components === "string"
        ? ruleData.components
        : parseDropdownToString(ruleData.components),
    modifiedByUser: userId,
    modifiedDateTime:
      ruleData && ruleData.modifiedDateTime
        ? ruleData.modifiedDateTime
        : undefined,
    comments: ruleData.comments || "",
  };
  const rules: IRules = { rules: [] };
  rules.rules.push(flagOverride);
  return rules;
};

export const convertDefaultTermRuleToRuleData = (
  defaultTermRule: IDefaultTermRule
): IRuleData => {
  return {
    ruleType: { key: 1, text: "Default Flag For Term" },
    term: defaultTermRule.term,
    flagArea: {
      key: defaultTermRule.defaultFlagAreaId.toString(),
      text: "",
    },
    flagLevel: {
      key: defaultTermRule.defaultFlagLevel.toString(),
      text: "",
    },
    overrideFlagArea: { key: "", text: "" },
    overrideFlagLevel: { key: "", text: "" },
    matchType: { key: defaultTermRule.matchType.toString(), text: "" },
    components: [],
    language: { key: defaultTermRule.language, text: "" },
    country: { key: defaultTermRule.country, text: "" },
    advertiserName: { key: -1, name: "" },
    bypassType: { key: "", text: "" },
    modifiedBy: defaultTermRule.modifiedByUser,
    id: defaultTermRule && defaultTermRule.id ? defaultTermRule.id : -1,
    modifiedDateTime:
      defaultTermRule && defaultTermRule.modifiedDateTime
        ? defaultTermRule.modifiedDateTime
        : undefined,
    reasonCode: defaultTermRule.reasonCode,
    comments: defaultTermRule.comments,
  };
};

export interface ISearchRuleFilter {
  fieldName: string;
  operator: string;
  value: any;
}

export const SearchRuleFileter: ISearchRuleFilter = {
  fieldName: "",
  operator: "",
  value: undefined,
};

export interface IRuleIds {
  ruleIds: number[];
  comments: string;
}

export const DefaultRuleIds: IRuleIds = {
  ruleIds: [],
  comments: "",
};

export const convertIds = (ids: number[], comments: string): IRuleIds => {
  const ruleIds: IRuleIds = {
    ruleIds: [...ids],
    comments: comments,
  };
  return ruleIds;
};

const getIntValue = (val: string | number | undefined): number => {
  if (typeof val === "undefined") return -1;
  return typeof val === "string" ? parseInt(val) : val;
};

//const originalString = "2024-01-05T11:46:08.683Z";
const getDateString = (date: Date | undefined): string | undefined => {
  if (!date) return undefined;
  if (date instanceof Date) {
    return date
      .toISOString()
      .replace(/[a-zA-Z]/g, " ")
      .trim();
  } else if (typeof date === "string") {
    // You may want to validate the string as a date before formatting
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate
        .toISOString()
        .replace(/[a-zA-Z]/g, " ")
        .trim();
    } else {
      // Handle invalid date string
      alert("invalid date string" + date);
      return "Invalid Date";
    }
  } else {
    alert("invalid date string" + date);
    return "Invalid Input";
  }
};

const parseImportDataToDefaultRule = (
  importData: ImportRuleDataDetails[]
): IDefaultTermImportRule[] => {
  return importData.map((data) => {
    const {
      action,
      term,
      defaultFlagAreaId,
      defaultFlagLevel,
      country,
      language,
      matchType,
      componentMask,
      componentMaskStr,
      id,
      modifiedDateTime,
      reasonCode,
      comments,
    } = data;

    return {
      action,
      language: language,
      hasGlobalCountryOverride: undefined,
      term,
      matchType: getIntValue(matchType),
      expiryDate: undefined,
      id: id > 0 ? id : undefined,
      defaultFlagAreaId: getIntValue(defaultFlagAreaId),
      defaultFlagLevel: getIntValue(defaultFlagLevel),
      country: getIntValue(country),
      componentMask,
      componentMaskStr,
      modifiedByUser: "",
      modifiedDateTime: getDateString(modifiedDateTime),
      reasonCode: reasonCode,
      comments: comments,
      // ? formatDate(modifiedDateTime)
      // : undefined,
    };
  });
};

export const parseTermOverrideImportData = (
  importData: ImportRuleDataDetails[]
): ITermOverrideImportRule[] => {
  return importData.map((data) => {
    const {
      action,
      term,
      id,
      advertiserId,
      advertiserName,
      overrideFlagAreaId,
      overrideFlagLevel,
      BypassType,
      matchType,
      country,
      language,
      modifiedDateTime,
      comments,
    } = data;

    return {
      action,
      term,
      id: id > 0 ? id : undefined,
      advertiserId,
      advertiserName,
      overrideFlagAreaId,
      overrideFlagLevel,
      BypassType: getIntValue(BypassType),
      matchType: getIntValue(matchType),
      country: getIntValue(country),
      language: getIntValue(language),
      source: 0,
      modifiedByUser: "",
      modifiedDateTime: getDateString(modifiedDateTime),
      comments: comments,
      // ? formatDate(modifiedDateTime)
      // : undefined,
    };
  });
};

export const parseFlagOverrideImportData = (
  importData: ImportRuleDataDetails[]
): IFlagOverrideImportRule[] => {
  return importData.map((data) => {
    const {
      action,
      id,
      defaultFlagAreaId,
      defaultFlagLevel,
      advertiserId,
      advertiserName,
      overrideFlagAreaId,
      overrideFlagLevel,
      BypassType,
      componentMask,
      componentMaskStr,
      country,
      modifiedDateTime,
      comments,
    } = data;

    return {
      action,
      id: id > 0 ? id : undefined,
      defaultFlagAreaId: getIntValue(defaultFlagAreaId),
      defaultFlagLevel: getIntValue(defaultFlagLevel),
      advertiserId,
      advertiserName,
      overrideFlagAreaId: getIntValue(overrideFlagAreaId),
      overrideFlagLevel: getIntValue(overrideFlagLevel),
      BypassType: getIntValue(BypassType),
      componentMask,
      componentMaskStr,
      country: getIntValue(country),
      modifiedByUser: "",
      modifiedDateTime: getDateString(modifiedDateTime),
      comments: comments,
      // ? formatDate(modifiedDateTime)
      // : undefined,
    };
  });
};
export const parseUrlData = (urlData: IUrlData): IRules => {
  const urlRule: IURLRule = {
    term: urlData.url || "",
    matchType: Number(urlData?.matchType?.key) || 0,
    defaultFlagAreaId: urlData.defaultFlagArea.key as number,
    defaultFlagLevel: urlData.defaultFlagLevel.key as number,
    country: urlData.country
      ? typeof urlData.country.key === "string"
        ? parseInt(urlData.country.key)
        : urlData.country.key
      : -1,
    comments: urlData.comments || "",
    id: urlData.id ? urlData.id : -1,
    modifiedDateTime: urlData.modifiedDateTime,
  };

  const rules: IRules = { rules: [] };
  rules.rules.push(urlRule);
  return rules;
};
export const parseUrlImportData = (
  importData: ImportRuleDataDetails[]
): IURLImportRule[] => {
  return importData.map((data) => {
    const {
      action,
      term,
      id,
      defaultFlagAreaId,
      defaultFlagLevel,
      matchType,
      country,
      modifiedDateTime,
      comments,
    } = data;

    return {
      action,
      term,
      matchType: getIntValue(matchType),
      id: id > 0 ? id : -1,
      defaultFlagAreaId: getIntValue(defaultFlagAreaId),
      defaultFlagLevel: getIntValue(defaultFlagLevel),
      country: getIntValue(country),
      modifiedDateTime: getDateString(modifiedDateTime),
      comments: comments || "",
    };
  });
};

export const API_CONFIG = {
  INSERT_DEFAULT_TERM: {
    functionName: "ruleActionCreators.insertDefaultTermRule",
    endpoint: endpoints.INSERT_NEW_DEFAULT_TERM_RULE,
    method: "POST",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(convertRuleDataToDefaultTermRule(ruleData, userId)),
    reducerSuccess: actionTypes.INSERT_NEW_DEFAULT_TERM_RULE,
    reducerFail: actionTypes.INSERT_NEW_DEFAULT_TERM_RULE_FAILED,
  },
  UPDATE_DEFAULT_TERM: {
    functionName: "updateDefaultTermRule",
    endpoint: endpoints.UPDATE_DEFAULT_TERM_RULE,
    method: "PUT",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(convertRuleDataToDefaultTermRule(ruleData, userId)),
    reducerSuccess: actionTypes.UPDATE_DEFAULT_TERM_RULE,
    reducerFail: actionTypes.UPDATE_DEFAULT_TERM_RULE_FAILED,
  },
  SEARCH_DEFAULT_TERM: {
    functionName: "ruleActionCreators.searchDefaultTermRule",
    endpoint: endpoints.SEARCH_DEFAULT_TERM_RULE,
    method: "POST",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => {
      const appliedFilters =
        presets.tablePresets &&
        presets.tablePresets.filters &&
        presets.tablePresets.filters.applied
          ? presets.tablePresets.filters.applied
          : [];
      const attributeDetails: ISearchRuleFilter[] = [];
      appliedFilters.forEach((item) => {
        if (item.key) {
          item.key.forEach((x) => {
            attributeDetails.push({
              fieldName: item.field,
              operator: item.condition,
              value: x,
            });
          });
        } else {
          attributeDetails.push({
            fieldName: item.field,
            operator: item.condition,
            value: item.value,
          });
        }
      });
      return JSON.stringify(attributeDetails);
    },
    reducerSuccess: actionTypes.SEARCH_DEFAULT_TERM_RULE,
    reducerFail: actionTypes.SEARCH_DEFAULT_TERM_RULE_FAILED,
  },
  DELETE_DEFAULT_TERM: {
    functionName: "deleteDefaultTermRule",
    endpoint: endpoints.DELETE_DEFAULT_TERM_RULE,
    method: "DELETE",
    // getRequestBody: (
    //   ruleData,
    //   presets,
    //   ids,
    //   importData,
    //   hasAllItemsOnFirstPage,
    //   reset,
    //   isResizingColumn,
    //   userId
    // ) => JSON.stringify(convertIds(ids), ruleData.comments || ""),
    getRequestBody: (
      ruleData, // Assuming ruleData has a property Comments
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(convertIds(ids, ruleData.Comments || "")),
    reducerSuccess: actionTypes.DELETE_DEFAULT_TERM_RULE,
    reducerFail: actionTypes.DELETE_DEFAULT_TERM_RULE_FAILED,
  },
  IMPORT_DEFAULT_RULE: {
    functionName: "importDefaultTermRule",
    endpoint: endpoints.IMPORT_DEFAULT_RULE,
    method: "POST",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(parseImportDataToDefaultRule(importData)),
    reducerSuccess: actionTypes.IMPORT_DEFAULT_TERM_RULE,
    reducerFail: actionTypes.IMPORT_DEFAULT_TERM_RULE_FAILED,
  },
  INSERT_TERM_OVERRIDE: {
    functionName: "insertTermOverrideRule",
    endpoint: endpoints.INSERT_NEW_TERM_OVERRIDE_RULE,
    method: "POST",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(convertRuleDataToTermOverrideRule(ruleData, userId)),
    reducerSuccess: actionTypes.INSERT_NEW_TERM_OVERRIDE_RULE,
    reducerFail: actionTypes.INSERT_NEW_TERM_OVERRIDE_RULE_FAILED,
  },
  UPDATE_TERM_OVERRIDE: {
    functionName: "updateTermOverrideRule",
    endpoint: endpoints.UPDATE_TERM_OVERRIDE_RULE,
    method: "PUT",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(convertRuleDataToTermOverrideRule(ruleData, userId)),
    reducerSuccess: actionTypes.UPDATE_TERM_OVERRIDE_RULE,
    reducerFail: actionTypes.UPDATE_TERM_OVERRIDE_RULE_FAILED,
  },
  SEARCH_TERM_OVERRIDE: {
    functionName: "searchTermOverrideRule",
    endpoint: endpoints.SEARCH_TERM_OVERRIDE_RULE,
    method: "POST",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => {
      const appliedFilters =
        presets.tablePresets &&
        presets.tablePresets.filters &&
        presets.tablePresets.filters.applied
          ? presets.tablePresets.filters.applied
          : [];
      const attributeDetails: ISearchRuleFilter[] = [];
      appliedFilters.forEach((item) => {
        if (item.key) {
          item.key.forEach((x) => {
            attributeDetails.push({
              fieldName: item.field,
              operator: item.condition,
              value: x,
            });
          });
        } else {
          attributeDetails.push({
            fieldName: item.field,
            operator: item.condition,
            value: item.value,
          });
        }
      });
      return JSON.stringify(attributeDetails);
    },
    reducerSuccess: actionTypes.SEARCH_TERM_OVERRIDE_RULE,
    reducerFail: actionTypes.SEARCH_TERM_OVERRIDE_RULE_FAILED,
  },
  DELETE_TERM_OVERRIDE: {
    functionName: "deleteTermOverrideRule",
    endpoint: endpoints.DELETE_TERM_OVERRIDE_RULE,
    method: "DELETE",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) =>
      JSON.stringify(
        convertRuleDataToTermOverrideRuleForDelete(ruleData),
        ruleData.Comments || ""
      ), //JSON.stringify(convertIds(ids, ruleData.Comments || "")),
    reducerSuccess: actionTypes.DELETE_TERM_OVERRIDE_RULE,
    reducerFail: actionTypes.DELETE_TERM_OVERRIDE_RULE_FAILED,
  },
  IMPORT_TERM_OVERRIDE: {
    functionName: "importTermOverride",
    endpoint: endpoints.IMPORT_TERM_OVERRIDE_RULE,
    method: "POST",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(parseTermOverrideImportData(importData)),
    reducerSuccess: actionTypes.IMPORT_TERM_OVERRIDE_RULE,
    reducerFail: actionTypes.IMPORT_TERM_OVERRIDE_RULE_FAILED,
  },
  INSERT_FLAG_OVERRIDE: {
    functionName: "insertFlagOverrideRule",
    endpoint: endpoints.INSERT_NEW_FLAG_OVERRIDE_RULE,
    method: "POST",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(convertRuleDataToFlagOverrideRule(ruleData, userId)),
    reducerSuccess: actionTypes.INSERT_NEW_FLAG_OVERRIDE_RULE,
    reducerFail: actionTypes.INSERT_NEW_FLAG_OVERRIDE_RULE_FAILED,
  },
  UPDATE_FLAG_OVERRIDE: {
    functionName: "updateFlagOverrideRule",
    endpoint: endpoints.UPDATE_FLAG_OVERRIDE_RULE,
    method: "PUT",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(convertRuleDataToFlagOverrideRule(ruleData, userId)),
    reducerSuccess: actionTypes.UPDATE_FLAG_OVERRIDE_RULE,
    reducerFail: actionTypes.UPDATE_FLAG_OVERRIDE_RULE_FAILED,
  },
  SEARCH_FLAG_OVERRIDE: {
    functionName: "searchFlagOverrideRule",
    endpoint: endpoints.SEARCH_FLAG_OVERRIDE_RULE,
    method: "POST",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => {
      const appliedFilters =
        presets.tablePresets &&
        presets.tablePresets.filters &&
        presets.tablePresets.filters.applied
          ? presets.tablePresets.filters.applied
          : [];
      const attributeDetails: ISearchRuleFilter[] = [];
      appliedFilters.forEach((item) => {
        if (item.key) {
          item.key.forEach((x) => {
            attributeDetails.push({
              fieldName: item.field,
              operator: item.condition,
              value: x,
            });
          });
        } else {
          attributeDetails.push({
            fieldName: item.field,
            operator: item.condition,
            value: item.value,
          });
        }
      });
      return JSON.stringify(attributeDetails);
    },
    reducerSuccess: actionTypes.SEARCH_FLAG_OVERRIDE_RULE,
    reducerFail: actionTypes.SEARCH_FLAG_OVERRIDE_RULE_FAILED,
  },
  DELETE_FLAG_OVERRIDE: {
    functionName: "deleteFlagOverrideRule",
    endpoint: endpoints.DELETE_FLAG_OVERRIDE_RULE,
    method: "DELETE",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(convertIds(ids, ruleData.Comments || "")),
    reducerSuccess: actionTypes.DELETE_FLAG_OVERRIDE_RULE,
    reducerFail: actionTypes.DELETE_FLAG_OVERRIDE_RULE_FAILED,
  },
  IMPORT_FLAG_OVERRIDE: {
    functionName: "importFlagOverride",
    endpoint: endpoints.IMPORT_FLAG_OVERRIDE_RULE,
    method: "POST",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(parseFlagOverrideImportData(importData)),
    reducerSuccess: actionTypes.IMPORT_FLAG_OVERRIDE_RULE,
    reducerFail: actionTypes.IMPORT_FLAG_OVERRIDE_RULE_FAILED,
  },
  IMPORT_URL_RULE: {
    functionName: "importUrlRule",
    endpoint: endpoints.IMPORT_URL_RULE,
    method: "POST",
    getRequestBody: (
      ruleData,
      presets,
      ids,
      importData,
      hasAllItemsOnFirstPage,
      reset,
      isResizingColumn,
      userId
    ) => JSON.stringify(parseUrlImportData(importData)),
    reducerSuccess: actionTypes.IMPORT_URL_RULE,
    reducerFail: actionTypes.IMPORT_URL_RULE_FAILED,
  },
};

export interface ISearchRule {
  defaultTermRules: IDefaultTermRule[];
  termOverrideRule: ITermOverrideRule[];
  flagOverrideRule: IFlagOverrideRule[];
  urlRule: IURLRule[];
  exportData: IDefaultTermRule[] | ITermOverrideRule[] | IFlagOverrideRule[];
}
export const DefaultSearchRule: ISearchRule = {
  defaultTermRules: [],
  termOverrideRule: [],
  flagOverrideRule: [],
  urlRule: [],
  exportData: [],
};

export interface IUpdateRule {
  ruleData: IRuleData[];
}

export const DefaultUpdateRule: IUpdateRule = {
  ruleData: [],
};

export interface RulesRecord {
  [key: string]: string;
}
export interface SubResponseData {
  succeededRules: RulesRecord;
  failedRules: RulesRecord;
}
export interface ResponseData {
  statusCode: number;
  message: string;
  statusText: string;
  status: string;
  id: number;
  data: SubResponseData;
}

export interface ImportRuleReport {
  index: number;
  message: string;
  status: string;
}
